@import '~bootstrap/scss/bootstrap.scss';

/* Basic styling */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #1652a3;
}

a {
  color: #1377ff;
}
a:hover {
  text-decoration: underline;
}

footer {
  bottom: 0;
  text-align: center;
}

.btn {
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
