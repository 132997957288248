.about {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;

    margin-bottom: 30px;
    padding-top: 60px;
    padding-left: 40px;
    padding-bottom: 60px;
    color: #ffffff;

    background-image: url('./images/about.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;

    height: 526px;
  }

  @media all and (max-width: 468px) {
    h1 {
      height: 367px;
    }
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .btn {
    border-radius: 50px;
    text-align: center;
  }

  b {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    margin-left: 25px;
    margin-top: 25px;
  }

  p {
    font-style: normal;
    font-weight: normal;

    padding: 0.2rem;
  }

  u {
    margin-right: 25px;
  }

  ul.Documents {
    text-align: center;
  }

  p.s {
    padding: 0px;
  }
}
