.photouploader__preview {
  margin: 1rem;
  max-width: 300px;
  position: relative;
}

.photouploader__remove {
  border-radius: 50%;
  font-weight: bold;
  height: 1.5rem;
  line-height: 1;
  padding: 0 0 0.1rem 0;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  width: 1.5rem;
}

.photouploader__spinner {
  color: white;
  display: none;
  height: 3rem;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  width: 3rem;
}

.photouploader__preview--uploading {
  img {
    opacity: 50%;
  }

  .photouploader__spinner {
    display: block;
  }
}
