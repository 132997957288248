.resources-list {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;

    margin-bottom: 30px;
    padding-top: 60px;
    padding-left: 40px;
    padding-bottom: 60px;
    color: #ffffff;

    background-image: url('../images/Group1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(19, 119, 255, 0.6);

    height: 526px;
  }

  @media all and (max-width: 468px) {
    h1 {
      height: 367px;
    }
  }

  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .btn {
    border-radius: 50px;
    text-align: center;
  }

  div.col-sm {
    height: 25em;
  }

  img.img-fluid {
    width: 25%;
  }

  .resource__card {
    background-color: #1377ff;
    padding: 20px;
    margin-bottom: 30px;
    text-align: center;
    border-radius: 2rem;
    box-shadow: 0 0.4rem 0.6rem 0 rgba(0, 0, 0, 0.2), 0 0.8rem 1.2rem 0 rgba(0, 0, 0, 0.19);
    width: 100%;

    h3 {
      color: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }

    p {
      color: #00008b;
      margin: 0;
    }

    p.contact-info {
      color: white;
    }
  }

  .contact-info {
    color: white;
  }

  b {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    margin-left: 25px;
    margin-top: 25px;
  }

  p {
    font-style: normal;
    font-weight: normal;

    // padding: 25px;
  }

  u {
    margin-right: 25px;
  }

  ul.Documents {
    text-align: center;
  }

  p.s {
    padding: 0px;
  }
}
