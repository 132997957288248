.home {
  h1 {
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;

    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  h2 {
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    text-align: center;
    padding-top: 30px;
    padding-bottom: 250px;
  }

  div.Welcome {
    background-image: url('images/ImageBackGnd.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media all and (max-width: 468px) {
    .Welcome h1 {
      font-size: 40px;
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 0px;
      text-align: left;
    }

    .Welcome h2 {
      font-size: 13px;
      padding-top: 0px;
      padding-left: 30px;
      padding-right: 15px;
      text-align: left;
    }
  }

  div.container {
    margin-top: 30px;
    padding: 10px;
  }

  .category {
    display: flex;
    align-items: stretch;

    &:hover {
      text-decoration: none;
    }
  }

  .category__card {
    background-color: #1377ff;
    padding: 20px;
    margin-bottom: 30px;
    text-align: center;
    border-radius: 2rem;
    box-shadow: 0 0.4rem 0.6rem 0 rgba(0, 0, 0, 0.2), 0 0.8rem 1.2rem 0 rgba(0, 0, 0, 0.19);
    width: 100%;

    h3 {
      color: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }

    p {
      color: #00008b;
      margin: 0;
    }
  }

  div.row {
    text-align: center;
  }

  img {
    width: 30%;
  }

  b {
    color: #00008b;
    font-style: normal;
    font-weight: 450;
    font-size: 15px;

    text-align: center;
  }
}
